import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

// TODO: when we are deploying to environments, we'll need to manage which env var is used here
// currently prod and stg endpoints are stored in secrets manager

const appEnv = process.env.REACT_APP_ENV;
const baseEndpoint = process.env.REACT_APP_CMS_BASE_PATH;

export const SiteContext = createContext({});

export const SiteProvider = (props) => {
    const [displayLanguage, setDisplayLanguage] = useState(() => {
      const saved = localStorage.getItem("displayLanguage");
      const initialValue = JSON.parse(saved);
      return initialValue || "en";
    });

    const [siteData, setSiteData] = useState(null);

    const languagePath = `/${displayLanguage}`;
    const searchPath = ( displayLanguage == 'en' ) ? '' : `_${displayLanguage}`;

    const endpoint = `${baseEndpoint}${appEnv}/content${languagePath}/site.manifest.json`;

    const { children } = props;
    
    const { data } = useQuery(['sitedata', languagePath], async () => 
      await axios(endpoint)
        .then(res => res.data)
    );

    useEffect(() => {
      localStorage.setItem("displayLanguage", JSON.stringify(displayLanguage));
    }, [displayLanguage]);

    useEffect(() => {
      if (data)
        setSiteData(data);
    }, [data]);
 
    return (
        <SiteContext.Provider value={{ displayLanguage, languagePath, searchPath, setDisplayLanguage, siteData }}>
            {children}
        </SiteContext.Provider>
    );
};

export default SiteContext;
