import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import ObjectPage from 'components/pages/collection/ObjectPage';
import ImageDetectPage from 'components/pages/ImageDetect';
import { SiteProvider } from 'site/SiteContext';
import { RedirectRoutes } from 'helpers/DynamicRedirects';
import PageFooter from 'components/organisms/nav/PageFooter';
import MenuHeader from 'components/organisms/nav/MenuHeader';
import SiteAlert from 'components/organisms/SiteAlert';
import ScrollToTop from 'helpers/scrolltotop';
import SearchProvider from 'helpers/search';
import TagManager from 'react-gtm-module';

const queryClient = new QueryClient({
 defaultOptions: {
   queries: {
     refetchOnWindowFocus: false,
   },
 },
});

const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

const App = () => {

  return (
    <QueryClientProvider client={queryClient}>
        <SiteProvider>
            <SearchProvider>
                <SiteAlert />
                <MenuHeader />
                <ScrollToTop>
                    <Switch>
                      <Route exact path="/art-detect" component={ImageDetectPage} />;
                      <Route exact path="/art/collection/object/:objectID" component={ObjectPage} />;
                      <RedirectRoutes />
                    </Switch>
                </ScrollToTop>
                <PageFooter />
            </SearchProvider>
        </SiteProvider>
    </QueryClientProvider>
  );
};

export default App;
