import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import HomePageHeroWidget from './HomePageHeroWidget';

const HomePageHeroCarousel = (props) => {
    const { paragraph } = props;
    const { field_slide_delay } = paragraph.attributes;
    const { field_homepage_heroes } = props.paragraph.relationships;
    const [ current, setCurrent ] = useState(0);

    useEffect(() => {
        const next = (current + 1) % field_homepage_heroes.data.length;
        const id = setTimeout(() => setCurrent(next), (field_slide_delay*1000));
        return () => clearTimeout(id);
    }, [current]);

    const next = () => {
        setCurrent((current + 1) % field_homepage_heroes.data.length);
    };

    const prev = () => {
        setCurrent((current > 0) ? (current - 1) : field_homepage_heroes.data.length - 1);
    };

    const widgets = field_homepage_heroes.data.map((hero, index) => {
        return <HomePageHeroWidget paragraph={hero} key={index} prev={prev} next={next} />;
    });

    return (
        <>
            {widgets[current]}        
        </>
    );

};

export default withRouter(HomePageHeroCarousel);
