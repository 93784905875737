import React from 'react';
import { withRouter } from 'react-router';
import { Hero } from 'dma-ui-kit';
import { handleCTA } from 'helpers/handlecta';

const StackItem = (props) => {
  const { history, paragraph, right } = props;
  const { attributes, relationships } = paragraph;
  const {
    field_color,
    field_dark,
    field_hero_body,
    field_hero_title,
    field_hero_link
  } = attributes;

  const { title } = field_hero_link;
  const { field_media_image } = relationships.field_media.data[0].relationships;

  const image = field_media_image.data.attributes.uri.url;

  const followCTA = () => {
    handleCTA(field_hero_link.uri, history, window);
  };

  return (
    <Hero
      image={image}
      headline={field_hero_title}
      description={field_hero_body}
      color={field_color}
      dark={field_dark}
      action={followCTA}
      actionLabel={title}
      right={right}
    />
  );
};

export default withRouter(StackItem);
