
export const handleCTA = (link, history, window) => {
    if (link.includes('mailto')) {
        window.location = ctaLink
    } else if (link.includes('#')) {
        window.location.hash = ctaLink.replace('#', '');
    } else if (link.includes('http')) {
        window.open(link, '_blank');
    } else {
        const ctaLink = link.replace('internal:', '');
        history.push(ctaLink); 
    }

    return;
};
