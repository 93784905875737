import { QuoteBoxHero } from 'dma-ui-kit';
import { handleCTA } from 'helpers/handlecta';

const QuoteHero = (props) => {
    const { paragraph } = props;

    const { field_body, field_cta_link, field_title } = paragraph.attributes;
    const imgSrc = paragraph.relationships.field_media.data[0].relationships.field_media_image.data.attributes.uri.url;

    const followCTA = () => {
        handleCTA(field_cta_link.uri, history, window);
    };

    return (
        <>
            <QuoteBoxHero
                image={imgSrc}
                headline={field_title}
                description={field_body}
                actionLabel={field_cta_link.title}
                action={followCTA}
            />
        </>
    );
};

export default QuoteHero;