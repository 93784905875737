import { React, useContext } from 'react';
import { withRouter } from 'react-router';
import { SiteContext } from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { getOpenHours } from 'helpers/time';
import { get } from 'lodash';
import { Menu, MenuContext } from 'dma-ui-kit';
import { isBefore } from 'date-fns';
import { handleCTA } from 'helpers/handlecta';

const MenuHeader = (props) => {
    const { history } = props;
    const { displayLanguage, setDisplayLanguage, siteData } = useContext(SiteContext);

    const labels = { 
      blog: translateInterfaceText('Blog'),
      calendar: translateInterfaceText('Calendar'),
      hours: getOpenHours(), 
      hoursLabel: translateInterfaceText('Hours'), 
      membership: translateInterfaceText('Become a Member'),
      search: translateInterfaceText('Search'),
      shop: translateInterfaceText('Shop'),
      social: translateInterfaceText('Follow Us'),
      subscribe: translateInterfaceText('Subscribe'),
    };
    
    const UpdateInterfaceLanguage = (props) => setDisplayLanguage(props);

    const eyebrowList = (siteData && siteData.eyebrow) ? siteData.eyebrow.filter(eyebrow => 
      isBefore(Date.parse(eyebrow.start), new Date) &&
      isBefore(new Date, Date.parse(eyebrow.end))
    ): [];

    const menuSections = [];
    const menuData = get(siteData, 'menus.main-1.data', []);
    menuData.forEach(item => {
      if(!item.attributes.parent) {
        menuSections.push({ label: item.attributes.title, slug: item.attributes.url, sections: [] });

        menuData.forEach(subitem => {
          const subLinks = [];

          if (subitem.attributes.parent == item.id)
          {
            menuData.forEach(nodeItem => {
              if (nodeItem.attributes.parent == subitem.id)
                subLinks.push({ label: nodeItem.attributes.title, slug: nodeItem.attributes.url });
            });

            menuSections[menuSections.length - 1].sections.push({
              label: subitem.attributes.title,
              slug: subitem.attributes.url,
              links: subLinks
            });          
          }
        });
      }
    });

    const actions = {
      navigate: (uri) => { handleCTA(uri, history, window); },
      onLanguageChange: UpdateInterfaceLanguage,
      search: () => {}
    };

    const links={
      blog: '/blog',
      calendar: '/visit/calendar',
      membership: '/support/become-a-member',
      search: '/search',
      shop: '/shop',
      subscribe: '/newsletters'
    };
    
    return (
      <MenuContext
        actions={actions}
        labels={labels}
        links={links}
        navigation={menuSections}
        eyebrow={eyebrowList}
        language={displayLanguage}
      > 
        <Menu />
      </MenuContext>
    );
};

export default withRouter(MenuHeader);