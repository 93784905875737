import { withRouter } from 'react-router';
import { WhatsNewSection, HomepageSectionHeader} from 'dma-ui-kit';
import { handleCTA } from 'helpers/handlecta';

const HomePageNewsWidget = props => {

    const { history } = props;
    const { field_title_light, field_title_bold, field_subtitle, field_cta_link } = props.paragraph.attributes;

    const followCTA = () => {
        handleCTA(field_cta_link.uri, history, window);
    };
    
    const promotedNewsItems = [];

    return (
        <>
            <WhatsNewSection
                header={
                    <HomepageSectionHeader
                        boldHeadline={field_title_bold}
                        lightHeadline={field_title_light}
                        description={field_subtitle}
                        action={followCTA}
                        actionLabel={field_cta_link.title}
                    />
                }
            >
                {promotedNewsItems}
            </WhatsNewSection>
        </>
    );
};

export default withRouter(HomePageNewsWidget);
