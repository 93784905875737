import { withRouter } from 'react-router';
import { HomepageHero } from 'dma-ui-kit';
import { handleCTA } from 'helpers/handlecta';

const HomePageHeroWidget = (props) => {
    const { history, prev, next } = props;
    const { field_title_light, field_title_bold, field_subtitle, field_cta_link   } = props.paragraph.attributes;

    const { field_desktop_video, field_mobile_video, field_tablet_video } = props.paragraph.relationships;

    const desktop_media = (field_desktop_video.data.relationships.field_media_video_file) ? 
        field_desktop_video.data.relationships.field_media_video_file.data.attributes.uri.url:
        field_desktop_video.data.relationships.field_media_image.data.attributes.uri.url;
    const tablet_media = (field_tablet_video.data.relationships.field_media_video_file) ?
        field_tablet_video.data.relationships.field_media_video_file.data.attributes.uri.url:
        field_tablet_video.data.relationships.field_media_image.data.attributes.uri.url;
    const mobile_media = (field_mobile_video.data.relationships.field_media_video_file) ?
        field_mobile_video.data.relationships.field_media_video_file.data.attributes.uri.url:
        field_mobile_video.data.relationships.field_media_image.data.attributes.uri.url;

    // Due to how Drupal handles internal urls, strip garbage
    const followCTA = () => {
        handleCTA(field_cta_link.uri, history, window);
    };

    return (
        <>
            <HomepageHero
                lightHeadline={field_title_light}
                boldHeadline={field_title_bold}
                description={field_subtitle}
                actionLabel={field_cta_link.title}
                action={followCTA}
                src={desktop_media}
                desktop={desktop_media}
                tablet={tablet_media}
                mobile={mobile_media}
                prev={prev}
                next={next}
            />
        </>
    );
};

export default withRouter(HomePageHeroWidget);
